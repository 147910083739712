import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URLS } from "../../../Utils";

const ManageHotel = () => {
  const [tournamentName, setTournamentName] = useState([]);
  const [datesOfPlay, setDatesOfPlay] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamFrom, setTeamFrom] = useState("");
  const [players, setPlayers] = useState(
    Array(21).fill({ player: "", hotel: "", nightsSpent: "" })
  );

  const getAllProAmTournaments = async () => {
    try {
      const response = await axios.get(API_URLS.GET_ALL_50_TOURNAMENTS);
      const tournamentData = response.data.data?.result[0];
      setTournamentName(tournamentData);
    } catch (error) {
      // console.error("Error fetching tournament data:", error);
      alert("Failed to fetch tournament data");
    }
  };

  const handleInputChange = (index, type, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][type] = value;
    setPlayers(updatedPlayers);
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    // Do something with the selected tournament ID if needed
  };

  useEffect(() => {
    getAllProAmTournaments();
  }, []);

  return (
    <div className="container">
      <div
        className="input_group"
        style={{ display: "flex", marginBottom: "15px" }}
      >
        <label htmlFor="tournamentName">Name Of Tournament:</label>
        <select
          id="selectedTournament"
          onChange={handleSelectChange}
          className="selection"
          style={{ marginLeft: "60px" }}
        >
          <option>Please Select Tournament From Drop-Down</option>
          {tournamentName &&
            tournamentName.map((tournament, index) => (
              <option
                key={tournament.ParkId ?? index}
                value={tournament.ParkId}
              >
                {tournament.FirstName}
              </option>
            ))}
        </select>
      </div>
      <div
        className="input_group"
        style={{ display: "flex", marginBottom: "15px" }}
      >
        <label htmlFor="datesOfPlay">Dates of Play:</label>
        <input
          name="datesOfPlay"
          type="text"
          id="datesOfPlay"
          value={datesOfPlay}
          onChange={(e) => setDatesOfPlay(e.target.value)}
          className="input"
          placeholder="Date Of Play"
          style={{ marginLeft: "145px" }}
        />
      </div>
      <div
        className="input_group"
        style={{ display: "flex", marginBottom: "15px" }}
      >
        <label htmlFor="teamName">Team Name:</label>
        <input
          type="text"
          id="teamName"
          value={teamName}
          className="input"
          onChange={(e) => setTeamName(e.target.value)}
          style={{ width: "200px", marginLeft: "130px" }}
        />
        <label htmlFor="teamFrom" style={{ margin: "0px 50px" }}>
          From:
        </label>
        <input
          type="text"
          id="teamFrom"
          value={teamFrom}
          className="input"
          onChange={(e) => setTeamFrom(e.target.value)}
          style={{ width: "165px" }}
        />
      </div>

      <table width="100%">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style={{ textAlign: "left" }}>Hotel :</th>
            <th style={{ textAlign: "left" }}>Nights Spent :</th>
          </tr>
        </thead>
        <tbody>
          <option>Coach : </option>
          <td>
            <input
              type="text"
              //id={`player${index + 1}`}
              //value={player.player}
              // onChange={(e) =>
              //   handleInputChange(index, "player", e.target.value)
              // }
              style={{ width: "200px" }}
            />
          </td>
          <td>
            <input
              type="text"
              //id={`player${index + 1}`}
              //value={player.player}
              // onChange={(e) =>
              //   handleInputChange(index, "player", e.target.value)
              // }
              style={{ width: "200px" }}
            />
          </td>
          <td>
            <input
              type="text"
              //id={`player${index + 1}`}
              //value={player.player}
              // onChange={(e) =>
              //   handleInputChange(index, "player", e.target.value)
              // }
              style={{ width: "50px" }}
            />
          </td>
          {players.map((player, index) => (
            <tr key={index}>
              <option>Player : </option>
              <td>
                <input
                  type="text"
                  id={`player${index + 1}`}
                  value={player.player}
                  onChange={(e) =>
                    handleInputChange(index, "player", e.target.value)
                  }
                  style={{ width: "200px" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  id={`hotel${index + 1}`}
                  value={player.hotel}
                  onChange={(e) =>
                    handleInputChange(index, "hotel", e.target.value)
                  }
                  style={{ width: "200px" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  id={`nightsSpent${index + 1}`}
                  value={player.nightsSpent}
                  onChange={(e) =>
                    handleInputChange(index, "nightsSpent", e.target.value)
                  }
                  style={{ width: "50px" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageHotel;
