import React, { useEffect, useState } from "react";
import "./Rememberlegends.css";
import { API_URLS, BASE_URL } from "../../../../Utils";
import axios from "axios";
import Legends from "../Pop-ups/Legends/Legends";

const RememberingLegends = () => {
  const [legends, setLegends] = useState([]);
  const [showLegend, setShowLegend] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedDod, setSelectedDod] = useState("");

  const openLegendModal = (Description, Image, FirstName, LastName, Dod) => {
    setSelectedDescription(Description);
    setSelectedImage(Image);
    setSelectedFirstName(FirstName);
    setSelectedLastName(LastName);
    setSelectedDod(Dod);
    setShowLegend(true);
  };
  const closeLegendModal = () => {
    setShowLegend(false);
    setSelectedDescription(null);
    setSelectedImage(null);
    setSelectedFirstName(null);
    setSelectedFirstName(null);
    setSelectedDod(null);
  };

  const getAllLegends = async () => {
    try {
      const response = await axios.get(API_URLS.GET_ALL_LEGENDS);
      if (response.status === 200 && response.data.status === "success") {
        // console.log("Legendsdssdsd", response.data.data.result);
        setLegends(response.data.data.result);
      } else {
        console.error("Error deleting Schedule:", response.data);
      }
    } catch (error) {
      console.error("Error deleting Schedule:", error);
    }
  };

  useEffect(() => {
    getAllLegends();
  }, []);
  return (
    <section className="players_section py-4" id="legends">
      <div className="container">
        <div className="sc_title">
          <small>REMEMBERING OUR LEGENDS</small>
          <h3 shadow-text="Legends">All Legends</h3>
        </div>
      </div>

      <div className="image-grid-container">
        <div className="image-grid">
          {legends.map((Legend) => (
            <div
              key={Legend.LID}
              className={`grid-item grid-item-${(Legend.LID % 8) + 1}`}
            >
              <img
                src={`${BASE_URL}/api/v1/files/LegendImage/${Legend.LegendImage}`}
                alt={`image-${Legend.LID}`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openLegendModal(
                    Legend.ContributionInSports,
                    `${BASE_URL}/api/v1/files/LegendImage/${Legend.LegendImage}`,
                    Legend.FirstName,
                    Legend.LastName,
                    Legend.DiedOn
                  )
                }
              />
              {showLegend && (
                <Legends
                  isOpen={showLegend}
                  onClose={closeLegendModal}
                  Description={selectedDescription}
                  Image={selectedImage}
                  FirstName={selectedFirstName}
                  LastName={selectedLastName}
                  Diedon={selectedDod}
                />
              )}

              <div className="overlay">
                <div className="text">
                  {Legend.FirstName} {Legend.LastName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { RememberingLegends };
