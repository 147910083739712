import React, { useState, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";

import { API_URLS, BASE_URL } from "../../../../Utils";
import swal from "sweetalert";

const UploadStandings = ({ isOpen, onClose, ParkId }) => {
  const [fiftyName, setFiftyName] = useState("");
  const [fiftyFile, setFiftyFile] = useState("");
  const [sixtyName, setSixtyName] = useState("");
  const [sixtyFile, setSixtyFile] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);

  const fiftyInputRef = useRef(null);
  const sixtyInputRef = useRef(null);

  const handlefiftyButtonClick = (e) => {
    e.preventDefault();
    fiftyInputRef.current.click();
  };

  const handlefiftyChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setFiftyName(file.name);

        let data = new FormData();
        data.append("FiftyStanding", file);
        const response = await axios.post(API_URLS.Upload_FIFTY_STANDING, data);

        if (response.data.data.pdf.filename) {
          setFiftyFile(response.data.data.pdf.filename);
          {
            isRegisterDisabled
              ? swal("Info", "Waiting To Upload", "info")
              : swal("Success", "Standings Uploaded Successfully", "success");
          }

          // Disable the register button for 5 seconds
        }
        if (response.ok) {
          swal("Success", "Standings Uploaded Successfully", "success");
        }
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handlesixtyButtonClick = (e) => {
    e.preventDefault();
    sixtyInputRef.current.click();
  };

  const handlesixtyChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setSixtyName(file.name);

        let data = new FormData();
        data.append("SixtyStanding", file);
        const response = await axios.post(API_URLS.Upload_SIXTY_STANDING, data);

        if (response.data.data.pdf.filename) {
          setSixtyFile(response.data.data.pdf.filename);
          {
            isRegisterDisabled
              ? swal("Info", "Waiting To Upload", "info")
              : swal("Success", "Standings Uploaded Successfully", "success");
          }
          // Disable the register button for 5 seconds
        }
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // const { Year, Type } = formData;
    // if (!Year) {
    //   alert("Please Select Year From Drop-Down.", "", "info");
    //   return;
    // }
    // if (!Type) {
    //   alert("Please Select Game Type From Drop-Down.", "", "info");
    //   return;
    // }
    // try {
    //   const url = API_URLS.ADD_PDF_SCHEDULE;
    //   const response = await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(formData),
    //   });
    //   const data = await response.json();
    //   if (response.status == 200) {
    //     alert("Schedule Added Successfully");
    //   }
    //   onClose();
    // } catch (error) {
    //   console.error("Error:", error);
    //   alert("An error occurred while processing the request.");
    // }
  };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Upload Standings</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-12 input_group">
            <label htmlFor="uploadPdfFile">50's Points</label>
            <button
              className="btn btn_primary w-20"
              type="button"
              onClick={handlefiftyButtonClick}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={fiftyInputRef}
              style={{ display: "none" }}
              onChange={handlefiftyChange}
            />
            {fiftyFile && (
              <div>
                <a
                  href={`${BASE_URL}/api/v1/files/FiftyStanding/${fiftyFile}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fiftyName}
                </a>
              </div>
            )}
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="uploadPdfFile">60's Points</label>
            <button
              className="btn btn_primary w-20"
              type="button"
              onClick={handlesixtyButtonClick}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={sixtyInputRef}
              style={{ display: "none" }}
              onChange={handlesixtyChange}
            />
            {sixtyFile && (
              <div>
                <a
                  href={`${BASE_URL}/api/v1/files/TournamentPdf/${sixtyFile}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {sixtyName}
                </a>
              </div>
            )}
          </div>

          {/* <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled ? "Wait to Upload" : "Upload"}
            </button>
          </div> */}
        </form>
      </div>
    </Modal>
  );
};

export default UploadStandings;
