import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { decode, encode } from "html-entities";
import axios from "axios";
import swal from "sweetalert";
import { API_URLS, BASE_URL } from "../../../../Utils";
import he from "he";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Edit50Tournaments = ({ isOpen, onClose, parkId, refreshTournament }) => {
  const [loading, setLoading] = useState(false);
  const [tournamentName, setTournamentName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [date, setDate] = useState("");
  const [Description, setDescription] = useState("");
  const [imageName, setImageName] = useState("");
  const [TournamentPhoto, setTournamentPhoto] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const fileInputRef = useRef(null);
  const formatDate = (dateString) => {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (upload) => {
          setImageName(upload.target.result);
        };
        reader.readAsDataURL(file);

        let data = new FormData();
        data.append("tournamentImage", file);
        const response = await axios.post(
          API_URLS.UPLOAD_PROAM_TOURNAMENT_IMAGE,
          data
        );

        if (response.data.data.image.filename) {
          setTournamentPhoto(response.data.data.image.filename);
        }
      }
    } catch (error) {
      // console.error("Error uploading image:", error);
    }
  };

  const getProamByParkId = async (parkId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URLS.GET_PROAM_BY_ParkId}?selectedParkId=${parkId}`
      );
      if (response.status === 200) {
        const tournament = response.data.data.result[0];

        setTournamentName(tournament.FirstName);
        setEmail(tournament.EmailId);
        setPassword(tournament.Password);
        setIsCompleted(tournament.isCompleted);
        setTournamentPhoto(tournament.TournamentPhoto);
        setImageName(tournament.TournamentPhoto);
        setDate(formatDate(tournament.ProAmPlayingDate));
        setDescription(he.decode(tournament.Division));
        setLoading(false);
      } else {
        // console.error("Failed to fetch upcoming tournaments");
        setLoading(false);
      }
    } catch (error) {
      // console.error("Error fetching upcoming tournaments:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tournamentName.trim()) {
      swal("Please enter Tournament Name.");
      return;
    }
    if (!email.trim()) {
      swal("Please enter Email.");
      return;
    }
    if (!password.trim()) {
      swal("Please enter Password.");
      return;
    }
    if (!date || !date.trim()) {
      swal("Please enter Date.");
      return;
    }

    const param = {
      FirstName: tournamentName,
      LastName: "",
      Password: password,
      EmailId: email,
      StateId: "",
      CountyId: 0,
      City: "",
      IsSuperAdmin: 1,
      ParkId: parkId,
      // ProAmPlayingDate: convertedDate.toISOString(),
      ProAmPlayingDate: date ? new Date(date) : new Date(),
      IsProAm: true,
      NoOfGames: "",
      LevelOfPlay: "",
      Division: Description,
      HRLimitPerDivision: "",
      isCompleted: isCompleted,
      TournamentPhoto,
    };

    try {
      const response = await fetch(API_URLS.UPDATE_PROAM_TOURNAMENT, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      });

      if (response.ok) {
        alert("Tournament Updated Successfully", "success");
        refreshTournament();
        onClose();
      } else {
        throw new Error("Error Updating Tournament");
      }
    } catch (error) {
      // console.error("Error:", error.message);
      alert("Error Updating Tournament", "error");
    }
  };
  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      });
      setDate(formattedDate);
    } else {
      setDate(null);
    }
  };
  useEffect(() => {
    if (isOpen && parkId) {
      getProamByParkId(parkId);
    }
  }, [isOpen, parkId]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Tournament Registration Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
    >
      <div className="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Edit ProAm Tournament</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-12 input_group">
            <label htmlFor="tournamentName">Tournament Name *</label>
            <input
              id="tournamentName"
              className="input"
              type="text"
              value={tournamentName}
              onChange={(e) => setTournamentName(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="email">Email *</label>
            <input
              id="email"
              className="input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="date">Date *</label>
            <DatePicker
              selected={date ? new Date(date) : null}
              onChange={handleDateChange}
              className="input"
              placeholderText="Select Date"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div
            className="col-md-12 input_group"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            <label htmlFor="password">Password*</label>
            <input
              id="password"
              className="input"
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="isCompleted" style={{ marginLeft: "30px" }}>
              Is Completed?
            </label>
            <input
              id="isCompleted"
              className="input"
              type="checkbox"
              checked={isCompleted}
              onChange={(e) => setIsCompleted(e.target.checked)}
            />
          </div>
          {/* <div className="col-md-3 input_group"></div> */}
          {/* <div className="col-md-12 input_group">
            <label htmlFor="date">Date</label>
            <input
              id="date"
              className="input"
              type="text"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div> */}

          <div className="col-md-12 input_group" style={{ marginTop: "20px" }}>
            <label htmlFor="uploadPhoto">Upload Photo</label>
            <button
              className="btn btn_primary w-20"
              onClick={handleButtonClick}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
              className="input"
            />

            {(TournamentPhoto || imageName) && (
              <img
                src={
                  TournamentPhoto
                    ? `${BASE_URL}/api/v1/files/TournamentPhoto/${TournamentPhoto}`
                    : imageName
                }
                alt="Player"
                style={{ maxWidth: "150px" }}
              />
            )}
          </div>
          <ReactQuill
            name="summary"
            value={Description}
            onChange={(e) => {
              setDescription(e);
            }}
            placeholder="A brief description about yourself"
            style={{
              borderRadius: 8,
              height: "200px",
              marginBottom: "50px",
              width: "100%",
            }}
          />
          <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled ? "Wait to Upload the image" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Edit50Tournaments;
