import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import he from "he";
import { API_URLS, BASE_URL } from "../../../../../Utils";

const ReadMoreHOF = ({ isOpen, onClose, Description, Image }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Read More Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="rulesProcedures_modal ">
          <div className="modal_body">
            <div className="img_wrap">
              {Image ? (
                <img src={Image} alt="" style={{ height: "350px" }} />
              ) : (
                <div></div>
              )}
              <div className="content">{/* <h2>{tName}</h2> */}</div>
              <div className="rules-close-button">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg "
                  onClick={onClose}
                >
                  <path
                    d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                    fill="#1B1B1B"
                  />
                  <path
                    d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                    fill="#1B1B1B"
                  />
                  <path
                    d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                    fill="#1B1B1B"
                  />
                </svg>
              </div>
            </div>

            <div className="content">
              {Image ? (
                <div></div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "-30px",
                    }}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg "
                      onClick={onClose}
                    >
                      <path
                        d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                        fill="#1B1B1B"
                      />
                      <path
                        d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                        fill="#1B1B1B"
                      />
                      <path
                        d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                        fill="#1B1B1B"
                      />
                    </svg>
                  </div>
                  <center>{/* <h2>{tName}</h2> */}</center>
                </div>
              )}

              <center>
                <div
                  dangerouslySetInnerHTML={{ __html: he.decode(Description) }}
                />
              </center>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReadMoreHOF;
