import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import axios from "axios";
import DatePicker from "react-datepicker";
import { decode, encode } from "html-entities";
import "react-datepicker/dist/react-datepicker.css";
import { API_URLS, BASE_URL } from "../../../../Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { YearPicker } from "../HallOfFame";
//import "./ManageHallOfFameModal.css"; // Import the CSS file
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ManageHallOfFameModal = ({
  isOpen,
  onClose,
  hallOfFameId,
  year,
  description,
  image,
  getHallByYearHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [HallOfFameId, setHallOfFameId] = useState(hallOfFameId ?? 0);
  const [Year, setYear] = useState(year ?? 2025);
  const [Description, setDescription] = useState(decode(description ?? ""));
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    const file = e.target.files[0];
    if (file) {
      setImageName(URL.createObjectURL(file));
      setImageFile(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const addUpdateHallOfFame = async () => {
    try {
      debugger;
      setLoading(true);
      const response = await axios.post(API_URLS.ADD_UPDATE_HALL_OF_FAME, {
        HallOfFameId,
        Year,
        Description: encode(Description),
      });

      const newHallOfFameId = response.data.data.result[0][0];
      const extractedHOFId =
        typeof newHallOfFameId === "object" &&
        Object.values(newHallOfFameId)[0];
      // If there's an image file, upload it
      if (imageFile) {
        let data = new FormData();
        data.append("image", imageFile);
        data.append("hallOfFameId", extractedHOFId);

        await axios.post(API_URLS.UPLOAD_HALL_OF_FAME_IMAGE, data);
      }

      onClose();
      getHallByYearHandler(Year);
      setLoading(false);
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false);
      alert("An error occurred while processing the request.");
    }
  };

  useEffect(() => {
    setHallOfFameId(hallOfFameId ?? 0);
    setYear(year ?? 2025);
    setDescription(decode(description ?? ""));
  }, [hallOfFameId, year, description]);

  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",
      // Add some padding
      // Add other styles as needed
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Player Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div className="form_wrap teamReg_form">
        <div className="close-button">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <YearPicker value={Year} handleChange={setYear} />
        <ReactQuill
          name="summary"
          value={Description}
          onChange={(e) => {
            setDescription(e);
          }}
          placeholder="A brief description about yourself"
          style={{
            borderRadius: 8,
            height: "200px",
            marginBottom: "50px",
            width: "100%",
          }}
        />
        <div className="col-md-12 input_group">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label htmlFor="txtPhoto">Upload Photo</label>
            <input
              id="txtPhoto"
              type="file"
              name="txtPhoto"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <button
              className="btn btn_primary w-20"
              onClick={handleButtonClick}
            >
              Choose File
            </button>
          </div>
          {image && !imageName && (
            <img
              src={`${BASE_URL}/api/v1/files/HOFPhoto/${image}`}
              alt="Hall of Fame"
              style={{ maxWidth: "150px" }}
            />
          )}
          {imageName && (
            <img src={imageName} alt="Player" style={{ maxWidth: "150px" }} />
          )}
        </div>
        <button
          style={{ marginTop: "5%" }}
          onClick={addUpdateHallOfFame}
          disabled={loading || isRegisterDisabled}
          className="btn btn_primary w-100"
        >
          {isRegisterDisabled ? "Wait to Upload the image" : "Submit"}
        </button>
      </div>
    </Modal>
  );
};

export default ManageHallOfFameModal;
