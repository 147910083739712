import React from "react";
import { HomePage } from "./Home/Home.page";

const Admin = () => {
  return (
    <div>
      <HomePage />
    </div>
  );
};

export default Admin;
