import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Rules } from "../Pop-ups/Rules&Procedures/Rules";
import { TeamReg } from "../Pop-ups/TeamRegistration/TeamReg";
import { PlayerReg } from "../Pop-ups/PlayerRegistrations/PlayerReg";
import { Login } from "../Pop-ups/Login/Login";
import { Link } from "react-scroll";

const Navbar = () => {
  const [showRules, setShowRules] = useState(false);
  const [showTeamReg, setShowTeamReg] = useState(false);
  const [showPlayerReg, setShowPlayerReg] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const openRulesModal = () => {
    setShowRules(true);
  };
  const closeRulesModal = () => {
    setShowRules(false);
  };

  const openTeamRegModal = () => {
    setShowTeamReg(true);
  };

  const closeTeamRegModal = () => {
    setShowTeamReg(false);
  };

  const openPlayerRegModal = () => {
    setShowPlayerReg(true);
  };

  const closePlayerRegModal = () => {
    setShowPlayerReg(false);
  };

  const openLogInModal = () => {
    setShowLogin(true);
  };

  const closeLogInModal = () => {
    setShowLogin(false);
  };
  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 100,
      delay: 0,
      once: true,
      anchorPlacement: "top-bottom",
    });

    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        headerJs();
      }
    };

    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".header nav") &&
        !event.target.closest(".btn_navbar")
      ) {
        headerJs();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleNavbar = () => {
    document.querySelector(".header nav").classList.toggle("open");

    document.body.classList.toggle("scroll_off");
    document.querySelector(".btn_navbar").classList.toggle("open");
  };

  const headerJs = () => {
    document.querySelector(".header nav").classList.remove("open");

    document.body.classList.remove("scroll_off");
    document.querySelector(".btn_navbar").classList.remove("open");
  };

  return (
    <>
      <header className="header" data-aos="fade">
        <div className="container">
          <div className="sm_sc">
            <a href="#" className="logo">
              <img src="images/logo.png" alt="" />
            </a>

            <button className="btn_navbar" onClick={toggleNavbar}>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="30"></circle>
                <path
                  className="line--1"
                  d="M0 40h62c13 0 6 28-4 18L35 35"
                ></path>
                <path className="line--2" d="M0 50h70"></path>
                <path
                  className="line--3"
                  d="M0 60h62c13 0 6-28-4-18L35 65"
                ></path>
              </svg>
            </button>
          </div>

          <nav>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>

              <li style={{ cursor: "pointer" }}>
                <a onClick={openRulesModal} rel="modal:open">
                  Rules & Procedures
                </a>
                {showRules && (
                  <Rules isOpen={showRules} onClose={closeRulesModal} />
                )}
              </li>
              <Link to="schedule" smooth={true} duration={900}>
                <li style={{ marginRight: "inherit" }}>
                  <a>Standing/Schedule</a>
                </li>
              </Link>
              <li className="logo">
                <a href="#">
                  <img src="images/logo.png" alt="" />
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a onClick={openTeamRegModal}>Team Registration</a>
                {showTeamReg && (
                  <TeamReg isOpen={showTeamReg} onClose={closeTeamRegModal} />
                )}
              </li>
              <li style={{ cursor: "pointer" }}>
                <a onClick={openPlayerRegModal}>Player Registration</a>
                {showPlayerReg && (
                  <PlayerReg
                    isOpen={showPlayerReg}
                    onClose={closePlayerRegModal}
                  />
                )}
              </li>
              <li style={{ cursor: "pointer" }}>
                <a onClick={openLogInModal}>LOGIN</a>
                {showLogin && (
                  <Login isOpen={showLogin} onClose={closeLogInModal} />
                )}
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export { Navbar };
