import React, { useState, useEffect } from "react";
import "aos/dist/aos.css";

import { TeamReg } from "../Pop-ups/TeamRegistration/TeamReg";
import { PlayerReg } from "../Pop-ups/PlayerRegistrations/PlayerReg";
import { Link } from "react-scroll";

const Footer = () => {
  const [showTeamReg, setShowTeamReg] = useState(false);
  const [showPlayerReg, setShowPlayerReg] = useState(false);
  const openPlayerRegModal = () => {
    setShowPlayerReg(true);
  };

  const closePlayerRegModal = () => {
    setShowPlayerReg(false);
  };
  const openTeamRegModal = () => {
    setShowTeamReg(true);
  };

  const closeTeamRegModal = () => {
    setShowTeamReg(false);
  };

  return (
    <footer className="footer pt-4">
      <div className="container">
        <div className="col_1" data-aos="fade">
          <h2>Proam SoftBall</h2>
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a onClick={openTeamRegModal}>Team Registration</a>
              {showTeamReg && (
                <TeamReg isOpen={showTeamReg} onClose={closeTeamRegModal} />
              )}
            </li>
            <li style={{ cursor: "pointer" }}>
              <a onClick={openPlayerRegModal}>Player Registration</a>
              {showPlayerReg && (
                <PlayerReg
                  isOpen={showPlayerReg}
                  onClose={closePlayerRegModal}
                />
              )}
            </li>
            <Link to="schedule" smooth={true} duration={900}>
              <li>
                <a>Standing/Schedule</a>
              </li>
            </Link>
            <Link to="allplayers" smooth={true} duration={900}>
              <li>
                <a>All Players</a>
              </li>
            </Link>
            <Link to="hof" smooth={true} duration={500}>
              <li>
                <a>Hall Of Fame</a>
              </li>
            </Link>
            <Link to="legends" smooth={true} duration={500}>
              <li>
                <a>Remembering Legends</a>
              </li>
            </Link>
          </ul>
        </div>
        <div className="col_2">
          <p>©. All rights reserved.</p>
          <span>
            <a href="#">Terms</a>
            <a href="#">Privacy</a>
            <a href="#">Cookies</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
