import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";

import { API_URLS, BASE_URL } from "../../../../Utils";

const Uploadpdf = ({ isOpen, onClose, ParkId }) => {
  const [Year, setYear] = useState("2024");
  const [Type, setType] = useState("");
  const [Name, setName] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [tournamentList, setTournamentList] = useState([]);
  const [selectTournament, setSelectTournament] = useState("");

  const formData = {
    Name,
    Year,
    Type,
    ParkId: selectTournament,
    ScheduleFile: pdfFile,
  };
  const fnTournamentList = () => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${Year}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const handleTournamentChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const ParkId = selectedOption.value;
    const FirstName = selectedOption.textContent;
    setSelectTournament(ParkId);
  };
  const pdfInputRef = useRef(null);

  const handlePdfButtonClick = (e) => {
    e.preventDefault();
    pdfInputRef.current.click();
  };

  const handlePdfChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setPdfName(file.name);

        let data = new FormData();
        data.append("tournamentSchedulePdf", file);
        const response = await axios.post(API_URLS.Upload_PDF_SCHEDULE, data);

        if (response.data.data.pdf.filename) {
          setPdfFile(response.data.data.pdf.filename);

          // Disable the register button for 5 seconds
        }
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { Year, Type, ParkId, Name, ScheduleFile } = formData;
    if (!Year) {
      alert("Please Select Year From Drop-Down.", "", "info");
      return;
    }
    if (!ParkId) {
      alert("Please Select Tournament From Drop-Down.", "", "info");
      return;
    }
    if (!Type) {
      alert("Please Select Game Type From Drop-Down.", "", "info");
      return;
    }
    if (!Name) {
      alert("Please Write The PDF Name.", "", "info");
      return;
    }
    if (!ScheduleFile) {
      alert("Please Choose File.", "", "info");
      return;
    }
    // console.log("Form data", formData);

    try {
      const url = API_URLS.ADD_PDF_SCHEDULE;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.status == 200) {
        alert("Schedule Added Successfully");
      }
      onClose();
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };
  useEffect(() => {
    fnTournamentList();
  }, [Year]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Upload Schedule PDF</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          {/* <div className="col-md-12 input_group">
            <label htmlFor="year">Year *</label>
            <select
              value={Year}
              onChange={(e) => setYear(e.target.value)}
              className="input input_select"
            >
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div> */}

          <div className="col-md-12 input_group">
            <label htmlFor="Type">
              Year
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              value={Year}
              onChange={(e) => setYear(e.target.value)}
              className="input input_select"
            >
              {/* <select value="2024" className="selection"> */}
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="Type">
              Tournament
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              id="selectedTournament"
              value={selectTournament}
              onChange={handleTournamentChange}
              className="input input_select"
            >
              <option>Select Tournament</option>
              {tournamentList.data?.result.map((tournament) => (
                <option key={tournament.ParkId} value={tournament.ParkId}>
                  {tournament.FirstName}
                </option>
              ))}
            </select>
            {/* <select name="" id="" className="selection">
                  <option value="">Select Tournament</option>
                  <option value="">Tournament 02</option>
                  <option value="">Tournament 03</option>
                </select> */}
          </div>

          <div className="col-md-12 input_group">
            <label htmlFor="Type">
              Game Type
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              id="Type"
              value={Type}
              className="input input_select"
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="0">Select Game-Type</option>
              <option value="Season">Season</option>
              <option value="Tournament">Tournament</option>
            </select>
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="Name">
              PDF Name
              <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="Name"
              id="Name"
              className="input"
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="uploadPdfFile">
              Upload PDF
              <span className="edd-required-indicator">*</span>
            </label>
            <button
              className="btn btn_primary w-20"
              type="button"
              onClick={handlePdfButtonClick}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={pdfInputRef}
              style={{ display: "none" }}
              onChange={handlePdfChange}
            />
            {pdfFile && (
              <div>
                <a
                  href={`${BASE_URL}/api/v1/files/TournamentPdf/${pdfFile}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pdfName}
                </a>
              </div>
            )}
          </div>

          <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled ? "Wait to Upload the File" : "Register"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Uploadpdf;
