import React, { useEffect, useState } from "react";
import axios from "axios";

import SKReg from "./Components/SKReg";
import SkUpdate from "./Components/SkUpdate";

import { API_URLS } from "../../../Utils";

const ManageScorekeeper = () => {
  const [scorekeeper, setScoreKeeper] = useState([]);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedScorekeeper, setSelectedScorekeeper] = useState(null);

  const openSKModal = () => {
    setShowRegistration(true);
  };
  const closeSkModal = () => {
    setShowRegistration(false);
  };

  const openEditModal = (scorekeeper) => {
    setSelectedScorekeeper(scorekeeper);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const getScoreKeeper = async () => {
    try {
      const ParkId = 0;
      const response = await axios.get(API_URLS.GET_ALL_SCOREKEEPER, {
        params: { ParkId },
      });
      setScoreKeeper(response.data.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteScoreKeeper = async (SCID) => {
    try {
      const response = await axios.delete(API_URLS.DELETE_SCOREKEEPER_BY_ID, {
        params: { Id: SCID },
      });
      if (response.status === 200 && response.data.status === "success") {
        getScoreKeeper();
      } else {
        console.error("Error deleting scorekeeper:", response.data);
      }
    } catch (error) {
      console.error("Error deleting scorekeeper:", error);
    }
  };

  const handleDelete = (scorekeeper) => {
    const { FirstName, LastName, SCID } = scorekeeper;
    const fullName = `${FirstName} ${LastName}`;
    const confirmDelete = window.confirm(
      `Are you Surely Want to Delete- "${fullName}" ??`
    );
    if (confirmDelete) {
      deleteScoreKeeper(SCID);
    }
  };

  // const handleEdit = (SCID) => {
  //   //getScoreKeeperById(SCID);
  //   setSelectedWinnerID(SCID);
  //   setRegModal(true);
  // };

  useEffect(() => {
    getScoreKeeper();
  }, []);

  return (
    <div className="container">
      <div className="tabs_wrapper" data-aos="fade" data-aos-delay="50">
        <div
          className="table_wrap"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <div className="">
              <h2 style={{ color: "black", fontWeight: "bold" }}>
                SCOREKEEPER MANAGEMENT
              </h2>
            </div>
          </div>
          <div className=" ">
            <button className="btn btn_primary text_upp" onClick={openSKModal}>
              Register Scorekeeper
            </button>
            {showRegistration && (
              <SKReg
                isOpen={showRegistration}
                onClose={closeSkModal}
                onRegister={getScoreKeeper}
              />
            )}
          </div>
        </div>

        <div className="tabs_container">
          <div className="tab_content active" data-tab="Schedules">
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="schedule-container orange-scrollbar">
            <table>
              <tbody>
                {scorekeeper &&
                  scorekeeper.map((scorekeeper) => (
                    <tr key={scorekeeper.SCID}>
                      <td>{scorekeeper.FirstName}</td>
                      <td>{scorekeeper.LastName}</td>
                      <td>{scorekeeper.EmailId}</td>
                      <td>{scorekeeper.PhoneNo}</td>
                      <td>
                        <button
                          className="btn btn_primary text_upp"
                          onClick={() => openEditModal(scorekeeper.SCID)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn_primary text_upp"
                          onClick={() => handleDelete(scorekeeper)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {scorekeeper.length === 0 && (
            <div>
              No Scorekeeper added yet. Click 'Register Scorekeeper' button to
              add new Scorekeeper.
            </div>
          )}
          {showEditModal && (
            <SkUpdate
              isOpen={showEditModal}
              onClose={closeEditModal}
              scorekeeper={selectedScorekeeper}
              onUpdate={getScoreKeeper}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageScorekeeper;
