import React from "react";
import "./Alert.css";

const Alert = ({ message, confirmPress, cancelPress, disable = false }) => {
  return (
    <div className="alert-box">
      <p>{message}</p>
      <div className="alert-buttons">
        <button disabled={disable} onClick={confirmPress}>
          Yes
        </button>
        <button onClick={cancelPress}>No</button>
      </div>
    </div>
  );
};

export { Alert };
