import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";

import { API_URLS } from "../../../../Utils";

const UmpireReg = ({ isOpen, onClose, Id, onUpdate }) => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Password, setPassword] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [Phone, setPhone] = useState("");
  const [Address, setAddress] = useState("");
  const [CanTravel, setCanTravel] = useState(false);
  const [ExperienceBox, setExperienceBox] = useState("");

  const formData = {
    ...(Id != null && { UID: Id }),
    FirstName,
    LastName,
    EmailId,
    Password,
    State,
    City,
    Phone,
    Address,
    CanTravel,
    ExperienceBox,
    ParkId: 0,
    IsAvailableWeekend: true,
  };

  const getUmpirebyId = async (Id) => {
    try {
      const response = await axios.get(API_URLS.GET_UMPIRE_By_ID, {
        params: { Id },
      });
      const data = response.data.data.result[0];

      setFirstName(data.FirstName || "");
      setLastName(data.LastName || "");
      setEmailId(data.EmailId || "");
      setPassword(data.Password || "");
      setState(data.State || "");
      setCity(data.City || "");
      setPhone(data.PhoneNo || "");
      setAddress(data.Address || "");
      setCanTravel(data.CanTravel || false);
      setExperienceBox(data.ExperienceBox || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { FirstName, LastName, EmailId, Password, Phone, Address } = formData;

    if (!FirstName.trim()) {
      alert("Please Enter First Name.", "", "info");
      return;
    }
    if (!LastName.trim()) {
      alert("Please Enter Last Name.", "", "info");
      return;
    }
    if (!EmailId.trim()) {
      alert("Please Enter Email ID.", "", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(EmailId.trim())) {
      alert("Please Enter a valid Email ID.", "", "info");
      return;
    }
    if (!Password.trim()) {
      alert("Please Enter Password.", "", "info");
      return;
    }
    if (!Phone.trim()) {
      alert("Please Enter Phone No.", "", "info");
      return;
    }
    if (!Address.trim()) {
      alert("Please Enter Address.", "", "info");
      return;
    }

    try {
      const url = Id != null ? API_URLS.UPDATE_UMPIRE : API_URLS.ADD_UMPIRE;

      const response = await axios({
        method: Id != null ? "patch" : "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        if (Id != null) {
          alert("Umpire Updated Successfully");
          onClose();
          onUpdate();
        } else {
          alert("Umpire Added Successfully");
        }
      } else {
        alert("An error Occured");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };

  useEffect(() => {
    if (Id) {
      getUmpirebyId(Id);
    }
  }, [Id]);
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Umpire Registration</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="firstName">
              First Name<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="firstName"
              id="firstName"
              className="input"
              type="text"
              value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="lastName">
              Last Name<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="lastName"
              id="lastName"
              className="input"
              type="text"
              value={LastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="emailId">
              Email ID<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="emailId"
              id="emailId"
              className="input"
              type="text"
              value={EmailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="password">
              Password<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="password"
              id="password"
              className="input"
              type="text"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="ddlState">State</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <select
                id="ddlState"
                name="ddlState"
                className="input input_select"
                value={State}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <input
                style={{
                  marginBottpm: "-20px",
                  marginLeft: "5px",
                }}
                name="txtCity"
                id="txtCity"
                value={City}
                onChange={(e) => setCity(e.target.value)}
                className="required edd-input input"
                placeholder="City"
                type="text"
              />
            </div>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="phoneNumber">
              Phone Number<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="phoneNumber"
              id="phoneNumber"
              className="input"
              type="text"
              value={Phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="Address">
              Address<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="Address"
              id="Address"
              className="input"
              type="text"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label>
              <input
                style={{ marginRight: "10px" }}
                type="checkbox"
                title="Willing To Travel"
                checked={CanTravel}
                onChange={(e) => setCanTravel(e.target.checked)}
              />
              Willing To Travel ?
            </label>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="experience">Experience</label>
            <textarea
              value={ExperienceBox}
              onChange={(e) => setExperienceBox(e.target.value)}
              className="required edd-input input"
              placeholder="Tell Us About Your Experience"
              type="text"
            ></textarea>
          </div>

          <div className="col-md-12 btn_wrap">
            <button type="submit" className="btn btn_primary w-100">
              Update
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UmpireReg;
