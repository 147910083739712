import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { BASE_URL } from "../../../../Utils";

import "./player.css";

const Player = ({ isOpen, onClose, PlayerId }) => {
  const [playerData, setPlayerData] = useState(null);
  const getSelectedAgent = async (PlayerId) => {
    //debugger;
    try {
      if (PlayerId) {
        const url = `${BASE_URL}/api/v1/web/player/get_players_by_playerid?SelectedPlayerId=${PlayerId}&SelectedTeamId=0`;
        const response = await axios.get(url);
        //console.log(response.data.data.result[0]);
        setPlayerData(response.data.data.result[0]);
      }
    } catch (error) {
      // console.error("Error fetching selected agent:", error);
      // console.error(error.message);
    }
  };

  useEffect(() => {
    getSelectedAgent(PlayerId);
  }, [PlayerId, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
    >
      <div>
        <h2>PLAYER INFORMATION</h2>
        {playerData ? (
          <table className="style1">
            <tbody>
              <tr>
                <td className="style2">First Name</td>
                <td className="style3">{playerData.FirstName}</td>
                <td rowSpan="11">
                  {/* Consider using an image component or CSS for displaying images */}
                  {/* <img src={playerImageSrc} alt="Player Image" /> */}
                </td>
              </tr>
              <tr>
                <td className="style2">Last Name</td>
                <td className="style3">{playerData.LastName}</td>
              </tr>
              <tr>
                <td className="style2">Email ID</td>
                <td className="style3">{playerData.EmailId}</td>
              </tr>
              <tr>
                <td className="style2">Jersey #</td>
                <td className="style3">{playerData.PlayerNumber}</td>
              </tr>
              <tr>
                <td className="style2">Bats</td>
                <td className="style3">{playerData.Bats}</td>
              </tr>
              <tr>
                <td className="style2">Throws </td>
                <td className="style3">{playerData.Throws}</td>
              </tr>
              <tr>
                <td className="style2">Throws </td>
                <td className="style3">{playerData.Throws}</td>
              </tr>

              <tr>
                <td className="style2">Bating </td>
                <td className="style3">{playerData.Bating}</td>
              </tr>
              <tr>
                <td className="style2">Fielding Position </td>
                <td className="style3">{playerData.Position}</td>
              </tr>
              <tr>
                <td className="style2">State</td>
                <td className="style3">{playerData.State}</td>
              </tr>
              <tr>
                <td className="style2">City</td>
                <td className="style3">{playerData.City}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
        <div className="modal-buttons">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default Player;
