import React, { useEffect, useState } from "react";
import { API_URLS } from "../../../Utils";
import axios from "axios";

const ParkReg = () => {
  const [selectedParkId, setSelectedParkId] = useState();
  const [form, setForm] = useState({
    Association: "ASA",
    City: "",
    EmailId: "",
    FieldCount: "1",
    FieldType: "Numbered",
    FirstName: "",
    Password: "",
    Phone: "",
    StateId: "",
    CountyId: "",
    LastName: "",
    IsProAm: "",
    ProAmPlayingDate: "",
    IsSuperAdmin: 0,
    ParkId: selectedParkId,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!form.City) newErrors.City = "Enter City";
    if (!form.FirstName) newErrors.FirstName = "Enter Park Name";
    if (!form.EmailId) newErrors.EmailId = "Enter EmailId ID";
    if (!/\S+@\S+\.\S+/.test(form.EmailId))
      newErrors.EmailId = "Enter Valid EmailId";
    if (!form.Password) newErrors.Password = "Enter Password";
    if (!form.Phone) newErrors.Phone = "Enter Phone #";
    if (!/^(\(?\+?[0-9]*\)?)?[0-9_\-\s()]*$/.test(form.Phone))
      newErrors.Phone = "Enter Valid Phone";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getParkByParkId = async (selectedParkId) => {
    //debugger;
    try {
      const response = await axios.get(API_URLS.GET_PARK_BY_PARKID, {
        params: { SelectedParkId: selectedParkId },
      });
      if (response.status === 200 && response.data.status === "success") {
        const parkData = response.data.data.result[0];

        setForm({
          StateId: parkData.State || "",
          City: parkData.City || "",
          FirstName: parkData.FirstName || "",
          FieldCount: parkData.FieldCount ? String(parkData.FieldCount) : "1",
          FieldType: parkData.FieldType || "Numbered",
          Association: parkData.Association || "ASA",
          EmailId: parkData.EmailId || "",
          Password: parkData.Password || "",
          Phone: parkData.Phone || "",
          CountyId: parkData.County,
          LastName: parkData.LastName,
          IsProAm: parkData.IsProAm,
          ProAmPlayingDate: parkData.ProAmPlayingDate,
          IsSuperAdmin: 0,
          ParkId: selectedParkId,
        });
      } else {
        console.error("Error Getting Park By ParkID");
      }
    } catch (error) {
      console.error("Error in Execution:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const url =
          selectedParkId != null ? API_URLS.UPDATE_PARK : API_URLS.ADD_PARK;
        const method = selectedParkId != null ? "PATCH" : "POST";
        const response = await fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          alert("Operation Successful");
        }

        const data = await response.json();
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    getParkByParkId(selectedParkId);
  }, [selectedParkId]);

  return (
    <div className="App">
      <div className="site-content">
        <div>
          <h2>Welcome To Park Registration</h2>
          <p>
            Following info of your park is correct? If not Plaese Update your
            park info.
          </p>
          <h3 style={{ marginLeft: "50%" }}>Park Registration</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <table className="style1">
            <tbody>
              <tr>
                <td width="100px">State *</td>
                <td colSpan="2">
                  <select
                    name="State"
                    value={form.State}
                    onChange={handleChange}
                    style={{ width: "205px" }}
                  >
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DC">District of Columbia</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>City *</td>
                <td colSpan="2">
                  <input
                    type="text"
                    name="City"
                    value={form.City}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  />
                  {errors.City && <span className="error">{errors.City}</span>}
                </td>
              </tr>
              <tr>
                <td>Park Name *</td>
                <td colSpan="2">
                  <input
                    type="text"
                    name="FirstName"
                    value={form.FirstName}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  />
                  {errors.FirstName && (
                    <span className="error">{errors.FirstName}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>No. of Fields *</td>
                <td colSpan="2">
                  <select
                    name="FieldCount"
                    value={form.FieldCount}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  >
                    {[...Array(10).keys()].map((i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Field Type *</td>
                <td colSpan="2">
                  <label>
                    <input
                      type="radio"
                      name="FieldType"
                      value="Numbered"
                      checked={form.FieldType === "Numbered"}
                      onChange={handleChange}
                    />
                    Numbered
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="FieldType"
                      value="Named"
                      checked={form.FieldType === "Named"}
                      onChange={handleChange}
                    />
                    Named
                  </label>
                </td>
              </tr>
              <tr>
                <td>Association *</td>
                <td>
                  <select
                    name="Association"
                    value={form.Association}
                    onChange={handleChange}
                    style={{ width: "100px" }}
                  >
                    <option value="ASA">ASA</option>
                    <option value="ISA">ISA</option>
                    <option value="USSSA">USSSA</option>
                    <option value="NSA">NSA</option>
                    <option value="SSAA">SSAA</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Email ID *</td>
                <td colSpan="2">
                  <input
                    type="EmailId"
                    name="EmailId"
                    value={form.EmailId}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  />
                  {errors.EmailId && (
                    <span className="error">{errors.EmailId}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Password *</td>
                <td colSpan="2">
                  <input
                    type="text"
                    name="Password"
                    value={form.Password}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  />
                  {errors.Password && (
                    <span className="error">{errors.Password}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Phone # *</td>
                <td colSpan="2">
                  <input
                    type="text"
                    name="Phone"
                    value={form.Phone}
                    onChange={handleChange}
                    style={{ width: "200px" }}
                  />
                  {errors.Phone && (
                    <span className="error">{errors.Phone}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <button
            type="submit"
            style={{ marginLeft: "90%", marginBottom: "5%" }}
          >
            {selectedParkId ? "Update" : "Register"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ParkReg;
