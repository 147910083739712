import React, { useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./paymentsuccess.css";
import { useLocation } from "react-router-dom";
import { API_URLS } from "../../../Utils";

const Paymentsuccess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ParkId = searchParams.get("ParkId");

  const TeamId = window.sessionStorage.getItem("TeamId");
  const hasCalled = useRef(false);

  const updatePaymentStatus = async () => {
    try {
      await axios.patch(API_URLS.UPDATE_PAYMENT_STATUS, { ParkId, TeamId });
      return true; // Indicate success
    } catch (error) {
      console.error("Error updating payment status:", error);
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    // console.log("Paymentsuccess component rendered");
    if (ParkId && TeamId && !hasCalled.current) {
      hasCalled.current = true;

      updatePaymentStatus().then((paymentUpdated) => {
        if (paymentUpdated) {
          // console.log("Payment status updated successfully!");
        }
      });
    }
  }, [ParkId, TeamId]);

  return (
    <div className="payment-success-container">
      <div className="checkmark">✓</div>
      <h1 className="ph1">Payment Successful!</h1>
      <p className="paymentp">
        Thank you for your purchase. Your payment has been successfully
        processed.
      </p>
      <Link to="/admin" className="btn">
        Go to Home
      </Link>
    </div>
  );
};

export { Paymentsuccess };
