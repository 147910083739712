import React, { useState } from "react";
import Modal from "react-modal";

import { API_URLS } from "../../../../Utils";

const SKReg = ({ isOpen, onClose, onRegister }) => {
  const [formData, setFormData] = useState({
    ParkId: 0,
    FirstName: "",
    LastName: "",
    EmailId: "",
    Password: "",
    State: "",
    City: "",
    PhoneNo: "",
    Address: "",
    ExperienceBox: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "State") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value, // Update state with the selected value
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    //debugger;
    e.preventDefault();
    const { FirstName, LastName, EmailId, Password } = formData;
    if (!FirstName.trim()) {
      alert("Please Enter First Name.", "", "info");
      return;
    }
    if (!LastName.trim()) {
      alert("Please Enter Last Name.", "", "info");
      return;
    }
    if (!EmailId.trim()) {
      alert("Please Enter Email ID.", "", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(EmailId.trim())) {
      alert("Please Enter a valid Email ID.", "", "info");
      return;
    }
    if (!Password.trim()) {
      alert("Please Enter Password.", "", "info");
      return;
    }

    try {
      const response = await fetch(API_URLS.ADD_SCOREKEEPER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        alert("Score-Keeper Added Successfully");
        // You can perform any additional actions here, like closing the modal or resetting the form
        onClose(); // Close the modal after successful submission
        // Reset the form fields
        setFormData({
          ParkId: 0,
          FirstName: "",
          LastName: "",
          EmailId: "",
          Password: "",
          State: "",
          City: "",
          PhoneNo: "",
          Address: "",
          ExperienceBox: "",
        });
        onRegister();
      } else {
        throw new Error(data.message || "Failed to add Score-Keeper");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };
  const customStyles = {
    content: {
      width: "80%",
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Scorekeeper Registration</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="FirstName">
              First Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="FirstName"
              value={formData.FirstName}
              onChange={handleChange}
              id="txtFirstName"
              className="input"
              placeholder="First Name"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="LastName">
              Last Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="LastName"
              id="LastName"
              value={formData.LastName}
              onChange={handleChange}
              className="input"
              placeholder="Last Name"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="EmailId">
              Email ID <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="EmailId"
              id="EmailId"
              value={formData.EmailId}
              onChange={handleChange}
              className="input"
              placeholder="Email ID"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="Password">
              Password <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="Password"
              id="Password"
              value={formData.Password}
              onChange={handleChange}
              className="input"
              placeholder="Password"
              type="text"
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="State">Location</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <select
                id="State"
                name="State"
                className="input input_select"
                value={formData.State}
                onChange={handleChange}
              >
                <option value="">State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <input
                style={{
                  marginBottpm: "-20px",
                  marginLeft: "5px",
                }}
                name="City"
                id="City"
                value={formData.City}
                onChange={handleChange}
                className="input"
                placeholder="City"
                type="text"
              />
            </div>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="Address">Address</label>
            <textarea
              value={formData.Address}
              onChange={handleChange}
              name="Address"
              id="Address"
              className="input"
              placeholder="Address"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="PhoneNo">Phone Number</label>
            <input
              value={formData.PhoneNo}
              onChange={handleChange}
              name="PhoneNo"
              id="PhoneNo"
              className="input"
              placeholder="Phone Number"
              type="text"
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="ExperienceBox">Experience</label>
            <textarea
              value={formData.ExperienceBox}
              onChange={handleChange}
              name="ExperienceBox"
              id="ExperienceBox"
              className="input"
              placeholder="Previous Experience"
              type="text"
            ></textarea>
          </div>

          <div className="col-md-12 btn_wrap">
            <button type="submit" className="btn btn_primary w-100">
              Register Now
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
//};
export default SKReg;
