import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from "../../../components/Alert";
import he from "he";

import RemLegends from "./Components/RemLegendUpdate";
import { API_URLS } from "../../../Utils";
import RemLegendAdd from "./Components/RemLegendAdd";

const RememberingLegends = () => {
  const [Legends, setLegends] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedLegendId, setSelectedLegendId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);

  const openEditModal = (LID) => {
    setSelectedLegendId(LID);
    setShowModal(true);
  };

  const closeEditModal = () => {
    setShowModal(false);
    setSelectedLegendId(null);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };
  const deleteLegend = async (LID) => {
    try {
      setDeleteLoading(true);
      await axios.delete(API_URLS.DELETE_LEGEND, {
        params: {
          LID,
        },
      });

      setDeleteLoading(false);
      setDeletedId(null);
      getAllLegends();
    } catch (error) {
      console.error("Error:", error);
      setDeleteLoading(false);

      alert("An error occurred while processing the request.");
    }
  };
  const getAllLegends = async () => {
    try {
      const response = await axios.get(API_URLS.GET_ALL_LEGENDS);
      if (response.status === 200 && response.data.status === "success") {
        //  console.log(response.data.data.result);
        setLegends(response.data.data.result);
      } else {
        console.error("Error deleting Schedule:", response.data);
      }
    } catch (error) {
      console.error("Error deleting Schedule:", error);
    }
  };

  useEffect(() => {
    getAllLegends();
  }, []);

  return (
    <div className="container">
      <div className="site-content">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ padding: "1%" }}>Remembering Our Legends</h3>
          <button className="btn btn_primary text_upp" onClick={openAddModal}>
            Add Legend
          </button>
        </div>
        <div className="tabs_container">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th style={{ textAlign: "center" }}>Last Name</th>
                <th style={{ textAlign: "center" }}>Died On</th>
                {/* <th style={{ textAlign: "center" }}>Contribution to Sports</th> */}
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Legends.map((Legend) => (
                <tr key={Legend.LID}>
                  <td>{Legend.FirstName}</td>
                  <td style={{ textAlign: "center" }}>{Legend.LastName}</td>
                  <td style={{ textAlign: "center" }}>{Legend.DiedOn}</td>
                  {/* <td style={{ textAlign: "center" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: he.decode(Legend.ContributionInSports),
                      }}
                    />
                  </td> */}
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => openEditModal(Legend.LID)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => {
                        const legendName = `"${Legend.FirstName.trim()} ${Legend.LastName.trim()}"`;
                        const isConfirmed = window.confirm(
                          `Are you sure you want to delete the legend ${legendName}?`
                        );
                        if (isConfirmed) {
                          deleteLegend(Legend.LID);
                        }
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showModal && (
          <RemLegends
            isOpen={showModal}
            onClose={closeEditModal}
            LID={selectedLegendId}
            onUpdate={getAllLegends}
          />
        )}

        {showAddModal && (
          <RemLegendAdd
            isOpen={showAddModal}
            onClose={closeAddModal}
            onAdd={getAllLegends}
          />
        )}
      </div>
    </div>
  );
};

export default RememberingLegends;
