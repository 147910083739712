import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import "./footer.css";

const AdminFooter = () => {
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    // Check if there's content in the main-content div
    const contentElement = document.querySelector(".main-content");
    if (contentElement && contentElement.innerHTML.trim() !== "") {
      setHasContent(true);
    }
  }, []);

  return (
    <footer
      className={`footer pt-4 ${hasContent ? "footer pt-4" : "no_content"}`}
    >
      <div className="container">
        <div className="col_1" data-aos="fade">
          <h2>Proam SoftBall</h2>
        </div>
        <div className="col_2">
          <p>©. All rights reserved.</p>
          <span>
            <a href="#">Terms</a>
            <a href="#">Privacy</a>
            <a href="#">Cookies</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export { AdminFooter };
