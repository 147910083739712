import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Player from "./Components/Player";
import { API_URLS } from "../../../Utils";
import { showAlert } from "../../../../../Frontend/src/components/SwalAlert/showAlert";

const AvailablePlayer = () => {
  const [player, setPlayer] = useState([]);
  const [showPlayer, setShowPlayer] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const TeamIDfromCoach = window.sessionStorage.getItem("TeamId");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPlayers = player.filter((player) =>
    `${player.FirstName} ${player.LastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const addPlayer = async (id) => {
    try {
      await axios.post(API_URLS.ADD_EXISTING_PLAYER, {
        TeamId: TeamIDfromCoach,
        PlayerId: id,
      });
      //showAlert("Success", "Player Added To Team.", "success");
      const targetUrl = `/admin/manage-50_team_players/${TeamIDfromCoach}`;
      navigate(targetUrl);
    } catch (error) {
      console.error("Error:", error);
      showAlert(
        "Error",
        "An error occurred while processing the request.",
        "error"
      );
    }
  };

  const getPlayerHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_PLAYER, {
        params: {
          TeamId: TeamIDfromCoach,
        },
      });
      setPlayer(data.result);
    } catch (error) {
      alert(error.response.data.message);
      //setLoading(false);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const handlePlayerClick = async (id) => {
    setSelectedPlayerId(id);
    setShowPlayer(true);
  };

  const closePlayerModal = () => {
    setShowPlayer(false);
  };

  useEffect(() => {
    getPlayerHandler();
  }, []);

  return (
    <div className="container" style={{ maxWidth: "97%" }}>
      <div className="tabs_container">
        <div className="tab_content active" data-tab="Schedules">
          <center>
            <h2>Available Players</h2>
            <div className="search-bar">
              <input
                type="text"
                className="search-input"
                placeholder="Search Player..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <strong>
              <h4>Click on Player's First Name to See the Stats</h4>
            </strong>
          </center>

          <div className="schedule-container orange-scrollbar">
            {loading ? (
              <h2 style={{ textAlign: "center", marginTop: "10%" }}>
                Loading...
              </h2>
            ) : (
              <div style={{ overflowY: "auto", maxHeight: "500px" }}>
                {" "}
                {/* Set max height and enable scrolling */}
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead
                    style={{ position: "sticky", top: 0, background: "#fff" }}
                  >
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Add Player</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPlayers.map((singlePlayer) => (
                      <tr key={singlePlayer?.PlayerId}>
                        <td
                          onClick={() =>
                            handlePlayerClick(singlePlayer?.PlayerId)
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {singlePlayer?.FirstName}
                        </td>
                        <td>{singlePlayer?.LastName}</td>
                        <td>{singlePlayer?.PhoneNumber}</td>
                        <td>{singlePlayer?.EmailId}</td>
                        <td>
                          <button
                            onClick={() => addPlayer(singlePlayer?.PlayerId)}
                            style={{
                              backgroundColor: "#fe5900",
                              width: "190.61px",
                              height: "44.59px",
                              color: "#fff",
                              border: "1px solid",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              borderRadius: "0.4rem",
                              fontFamily: "Poppins",
                              transition: "background-color 0.3s, color 0.3s",
                              cursor: "pointer",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#fff";
                              e.target.style.color = "#fe5900";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = "#fe5900";
                              e.target.style.color = "#fff";
                            }}
                          >
                            Add Player To Team
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {showPlayer && (
          <Player
            isOpen={showPlayer}
            onClose={closePlayerModal}
            PlayerId={selectedPlayerId}
          />
        )}
      </div>
    </div>
  );
};

export default AvailablePlayer;
