import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL, API_URLS } from "../../../Utils";

const Announcements = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectTournament, setSelectTournament] = useState("");
  const [tournamentList, setTournamentList] = useState([]);

  const [status, setStatus] = useState(false);

  const [playerStatus, setPlayerStatus] = useState(false);
  const [selectedTournamentParkId, setSelectedTournamentParkId] =
    useState(null);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]); // State to store players data
  const [loading, setLoading] = useState(false);

  // Fetch tournament list
  const fnTournamentList = () => {
    setLoading(false);
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        // console.error(error.message);
      });
  };

  // Handle tournament selection
  const handleTournamentChange = (e) => {
    setLoading(false);
    setPlayerStatus(false);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const ParkId = selectedOption.value;
    setSelectTournament(ParkId);
    if (ParkId) {
      getTeamsWithParkId(ParkId);
    } else {
      // setStatus(false);
      setTeams([]);
      setPlayers([]);
    }
  };

  // Fetch teams by park ID
  const getTeamsWithParkId = async (ParkId) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_TEAMS_BY_ID_WITH_TYPE, {
        params: { SelectedParkId: ParkId },
      });
      setSelectedTournamentParkId(ParkId);
      setTeams(data?.result);
      setStatus(true);
      // console.log("Teams", data?.result);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  // Fetch players for each team
  const getPlayersHandler = async (TeamId) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_PLAYERS_BY_TEAM_ID, {
        params: { SelectedTeamId: TeamId },
      });
      setPlayers((prevPlayers) => [...prevPlayers, ...data?.result]); // Append players

      // console.log("Players", data?.result);
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  const formatPhoneNumber = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, "");

    // Remove leading zero if present
    const withoutLeadingZero = cleaned.startsWith("0")
      ? cleaned.slice(1)
      : cleaned;

    // Add country code +1 for the US if not already present
    if (withoutLeadingZero.length === 10) {
      return `+1${withoutLeadingZero}`;
    } else if (withoutLeadingZero.startsWith("1")) {
      return `+${withoutLeadingZero}`;
    } else {
      // Handle other cases or return as is
      return `+${withoutLeadingZero}`;
    }
  };

  const handleClickPlayer = () => {
    // console.log("Players count", players.length);
    const numberss = players
      ?.filter((player) => player?.PhoneNumber)
      .map((player) => formatPhoneNumber(player?.PhoneNumber));
    //   .map((team) => team?.PhoneNumber);
    // console.log("Total Phone Numbers Count:", numberss);
    Swal.fire({
      // title: "Are you sure?",
      text: "Do you want to send an SMS to all the Players of the Teams?",
      icon: "warning",
      input: "textarea", // Adds a textarea input for the message
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      preConfirm: (message) => {
        if (!message) {
          Swal.showValidationMessage("Message is required"); // Show validation if no message is entered
          return message;
        }
        return message; // Return the message as the result
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const message = result.value;
        Swal.fire("Success!", "Messages sent successfully!", "success");

        const numbers = players
          ?.filter((player) => player?.PhoneNumber)
          .map((player) => formatPhoneNumber(player?.PhoneNumber));
        if (numbers.length === 0) {
          alert("No Players available");
          return;
        }
        // console.log("format", numbers);
        try {
          const apiKey =
            "9c2acefffe3d3489f6d138828a2bdc47-d9cc9b51-097d-4ae9-a838-8a960284b21f";
          // "9d2de217e7cafe53e183f17717582e33-2dbaca7d-e4d8-4cf5-ac03-3230f816a78a";
          const response = await axios.post(
            "https://l38wqw.api.infobip.com/sms/2/text/advanced", // Adjusted endpoint URL
            {
              messages: [
                {
                  from: "ProAm50Updates", // Sender ID
                  destinations: numbers.map((number) => ({
                    to: number,
                  })),
                  text: message,
                },
              ],
            },
            {
              headers: {
                Authorization: `App ${apiKey}`, // Use API key
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            Swal.fire("Success!", "Messages sent successfully!", "success");
          } else {
            Swal.fire("Error!", "Some messages failed to send.", "error");
          }
        } catch (error) {
          // console.error("Error sending messages:", error);
          Swal.fire("Error!", "Error sending messages.", "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "You have cancelled the action.", "error");
      }
    });
  };
  const handleClickCoach = () => {
    const selectednumbers = teams
      ?.filter((team) => team?.PhoneNumber)
      .map((team) => formatPhoneNumber(team?.PhoneNumber));
    //   .map((team) => team?.PhoneNumber);
    // console.log("Total Phone Numbers Count:", selectednumbers);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send an SMS to all the Coaches?",
      icon: "warning",
      input: "textarea", // Adds a textarea input for the message
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      preConfirm: (message) => {
        if (!message) {
          Swal.showValidationMessage("Message is required"); // Show validation if no message is entered
          return false;
        }
        return message; // Return the message as the result
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const message = result.value; // Get the message from the SweetAlert input
        Swal.fire("Success!", "Messages sent successfully!", "success");

        const selectedPhones = teams
          ?.filter((team) => team?.PhoneNumber)
          .map((team) => formatPhoneNumber(team?.PhoneNumber));

        if (selectedPhones.length === 0) {
          alert("No coaches available");
          return;
        }

        try {
          const apiKey =
            "9c2acefffe3d3489f6d138828a2bdc47-d9cc9b51-097d-4ae9-a838-8a960284b21f";

          const response = await axios.post(
            "https://l38wqw.api.infobip.com/sms/2/text/advanced", // Adjusted endpoint URL
            {
              messages: [
                {
                  from: "Home Listings ", // Sender ID
                  destinations: selectedPhones.map((number) => ({
                    to: number,
                  })),
                  text: message,
                },
              ],
            },
            {
              headers: {
                Authorization: `App ${apiKey}`, // Use API key
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            Swal.fire("Success!", "Messages sent successfully!", "success");
          } else {
            Swal.fire("Error!", "Some messages failed to send.", "error");
          }
        } catch (error) {
          console.error("Error sending messages:", error);
          Swal.fire("Error!", "Error sending messages.", "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "You have cancelled the action.", "error");
      }
    });
  };
  useEffect(() => {
    if (status && teams.length > 0) {
      const selectedTeamIds = teams.map((team) => team?.TeamId);
      setPlayers([]); // Reset players state

      Promise.all(
        selectedTeamIds.map((teamId) => getPlayersHandler(teamId))
      ).then((playersArrays) => {
        const allPlayers = playersArrays.flat(); // Flatten array of arrays
        // Set the new state
        setLoading(true);
        setPlayerStatus(true);
      });
    }
  }, [teams, status]);
  useEffect(() => {
    fnTournamentList();
  }, [selectedYear]);

  return (
    <div className="container" style={{ maxWidth: "95%" }}>
      <div className="tabs_wrapper" data-aos="fade" data-aos-delay="50">
        <h2 style={{ marginLeft: "42%" }}>Announcements</h2>
        <div
          className="table_wrap"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "8px",
            }}
          >
            <div className="col-md-2">
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="selection"
              >
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
            <div className="col-md-6">
              <select
                id="selectedTournament"
                value={selectTournament}
                onChange={handleTournamentChange}
                className="selection"
              >
                <option value="">Select Tournament</option>
                {tournamentList.data?.result.map((tournament) => (
                  <option key={tournament.ParkId} value={tournament.ParkId}>
                    {tournament.FirstName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {playerStatus === true && (
            <div className="tab_wrap">
              <button
                className="btn btn_primary text_upp"
                onClick={() => handleClickCoach()}
              >
                Send SMS to Coach
              </button>
              <button
                className="btn btn_primary text_upp"
                onClick={() => handleClickPlayer()}
              >
                Send SMS to Player
              </button>
            </div>
          )}
        </div>

        {!selectTournament && (
          <h3 style={{ textAlign: "center" }}>
            Please select a tournament from the list
          </h3>
        )}

        {!loading && selectTournament && <p>Loading...</p>}

        {playerStatus === true && (
          <div className="container" style={{ maxWidth: "99%" }}>
            <div className="tabs_container">
              <table>
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Coach Name</th>
                    <th>Email</th>
                    <th>Phone number</th>
                  </tr>
                </thead>
                <tbody>
                  {teams?.map((team) => (
                    <tr key={team?.TeamId}>
                      <td>{team?.TeamName}</td>
                      <td>{team?.CoachName}</td>
                      <td>{team?.CoachEmail}</td>
                      <td>{team?.PhoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Announcements;
