import React, { useEffect, useState } from "react";
import { API_URLS } from "../../../Utils";
import swal from "sweetalert";

const HomePage = () => {
  const PlayerId = window.sessionStorage.getItem("PlayerId");
  const [playerData, setPlayerData] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  // console.log("isAnonymous", isAnonymous);
  const getPlayerById = () => {
    const params = new URLSearchParams({
      SelectedPlayerId: PlayerId,
      SelectedTeamId: 0,
    }).toString();

    const url = `${API_URLS.GET_PLAYERS_BY_PLAYER_ID}?${params}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        const results = data.data.result[0];
        // console.log("Fetched data:", results);
        setPlayerData(results);
        setIsAnonymous(results.isAnonymous);
      })
      .catch((error) => {
        swal("Fetch error:", error.message);
      });
  };

  const handleIsAnonymousClick = async () => {
    const params = new URLSearchParams({
      SelectedPlayerId: PlayerId,
      SelectedTeamId: 0,
    }).toString();

    const url = `${API_URLS.SET_ISANONYMOUS_TO_TRUE}?${params}`;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      } else {
        swal("You Have Successfully Added Yourself as a Free Agent");
        setIsUpdated((prev) => !prev);
      }
      const data = await response.json();
      //  console.log(data);
    } catch (error) {
      swal("Fetch error:", error.message);
    }
  };

  useEffect(() => {
    if (PlayerId) {
      getPlayerById();
    }
  }, [isUpdated]);

  return (
    <div className="container">
      <h2 style={{ textAlign: "center" }}>OUR MISSION</h2>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          // color: "rgba(193, 193, 193, 1)",
          color: "black",
        }}
      >
        We at ProAm 50 Softball want to bring the game of Softball into the 21st
        Century.
      </p>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          color: "black",
        }}
      >
        By Providing All the Statistics of the game that make playing so
        enjoyable to the Player and Spectators alike.
      </p>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          color: "black",
        }}
      >
        We provide the Videos of the games so the Coach, Players, and Spectators
        can enjoy the Hits, Catches, Throws and Tag out Highlights that come
        with such a fun and competitive Sport.
      </p>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          color: "black",
        }}
      >
        We welcome any and all ideas and suggestions to help us become the
        Tournament association that satisfies your quest to enjoy this game to
        the fullest potential.
      </p>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          color: "black",
        }}
      >
        Email us at:{" "}
        <a href="mailto:proam50softball@gmail.com">proam50softball@gmail.com</a>
      </p>
      <p
        style={{
          textAlign: "left",
          lineHeight: 1.6,
          color: "black",
        }}
      >
        If there is a highlight you think should be on the Highlight reel. Then
        email us at:{" "}
        <a href="mailto:proam50softball@gmail.com">proam50softball@gmail.com</a>{" "}
        with The Game, inning, and Play and we will search and clip your
        highlight to add to the Highlight Reel.
      </p>
      <br />
      <h3>Thank you, and see you out on the field.</h3>
      {!isAnonymous && (
        <div className="col-md-12 btn_wrap">
          <button
            className="btn btn_primary w-100"
            style={{ marginTop: "10%", marginBottom: "0" }}
            onClick={handleIsAnonymousClick}
          >
            Register Yourself As A Free Agent
          </button>
        </div>
      )}
    </div>
  );
};
export { HomePage };
